'use strict';

import React from 'react';
import * as timeago from 'timeago.js';

class ActivityFeed extends React.Component {
  componentDidMount() {
    timeago.render(document.querySelectorAll('.timeago'));

    this.props.activityFeeds.slice(this.props.perPage + 1, this.props.activityFeeds.length).map((content, index) => {
      setTimeout(() => {
        let dateToday = new Date();
        let justNow = dateToday.setSeconds(dateToday.getSeconds() - 1);

        document.querySelector('.feed-container').insertAdjacentHTML(
          'afterbegin',
          `<div class='pt-6 pb-6 border-b'>
            <span class='text-sm'>${content}</span>
            <span class='text-sm text-gray-500 float-right hidden sm:inline-block timeago-new' datetime="${justNow}"></span>
            <span class='text-sm text-gray-500 sm:hidden block timeago-new' datetime="${justNow}"></span>
          </div>`
        );

        timeago.render(document.querySelectorAll('.timeago-new'));
      }, index * this.props.interval);
    });
  }

  activityFeedsByMins() {
    let results = this.props.activityFeeds.slice(0, this.props.perPage).map((content, index) => {
      let dateToday = new Date();
      let minutes = this.props.randomMinutes[Math.floor(Math.random() * this.props.randomMinutes.length)];

      return { content: content, timeAgo: dateToday.setMinutes(dateToday.getMinutes() - minutes), minutes: minutes };
    });

    return results;
  }

  render() {
    return (
      <div className='max-w-6xl'>
        <div className='pt-10'>
          <div className='font-bold text-2xl pb-6'>Latest Activity</div>
          <div className='feed-container'>
            {
              this.activityFeedsByMins()
                .sort((a, b) => a.minutes - b.minutes)
                .map((feed, index) => {
                  return (
                    <div className='pt-6 pb-6 border-b' key={feed.content}>
                      <span dangerouslySetInnerHTML={{ __html: feed.content }}></span>
                      <span className='text-sm text-gray-500 float-right hidden sm:inline-block timeago' dateTime={feed.timeAgo}></span>
                      <span className='text-sm text-gray-500 sm:hidden block timeago' dateTime={feed.timeAgo}></span>
                    </div>
                  );
                })
            }
          </div>
        </div>
      </div>
    );
  }
}

export default ActivityFeed
