import { Tabs, Carousel } from "flowbite";
import "site/styles/index";
import "tailwind/common/src/menuLinks";
import ActivityFeed from 'tailwind/common/components/ActivityFeed'

const initializeTabs = () => {
  const tabsElement = document.getElementById("service-tab");
  const tabElements = [
    {
      id: "trades",
      triggerEl: document.querySelector("#trades-tab"),
      targetEl: document.querySelector("#trades"),
    },
    {
      id: "household",
      triggerEl: document.querySelector("#household-tab"),
      targetEl: document.querySelector("#household"),
    },
    {
      id: "business",
      triggerEl: document.querySelector("#business-tab"),
      targetEl: document.querySelector("#business"),
    },
    {
      id: "creative",
      triggerEl: document.querySelector("#creative-tab"),
      targetEl: document.querySelector("#creative"),
    },
  ];

  const options = {
    defaultTabId: "trades",
    activeClasses: "border-gray-300 hover:text-gray-600",
    inactiveClasses: "border-transparent",
  };

  const tabs = new Tabs(tabsElement, tabElements, options, {});
};

const initializeCarousel = () => {
  const totalItem = 2;
  let currentIndex = 0;

  const nextItem = () => {
    const hideIndex = currentIndex == 0 ? 1 : 0;

    $(`.carousel-item-${hideIndex}`).fadeOut();
    $(`#carousel-indicator-${hideIndex}`).removeClass('bg-gray-700').addClass('bg-gray-200');
    $(`.carousel-item-${currentIndex}`).fadeIn();
    $(`#carousel-indicator-${currentIndex}`).removeClass('bg-gray-200').addClass('bg-gray-700');      
    currentIndex = (currentIndex + 1) % totalItem;
  };

  setInterval(nextItem, 3000);
}

const initializeWhatTheyThinkCarousel = () => {
  const totalItem = 3
  let currentIndex = 0

  const nextItem = () => {

    const hideIndex = currentIndex
    currentIndex = (currentIndex + 1) % totalItem

    $(`.carousel-what-they-think-item-${hideIndex}`).fadeOut(() => {
      $(`.carousel-what-they-think-item-${currentIndex}`).fadeIn()
    })

    $(`#carousel-what-they-think-indicator-${hideIndex}`).removeClass('bg-gray-700').addClass('bg-gray-200')
    $(`#carousel-what-they-think-indicator-${currentIndex}`).removeClass('bg-gray-200').addClass('bg-gray-700')
  };

  setInterval(nextItem, 4000)
};

const initializeTypewriter = () => {
  const words = ["Builders", "Plumbers", "Painters"];
  const staticText = "Hire Great Local ";
  let i = 0;

  function typeNow() {
    const word = words[i].split("");
    let currentText = staticText;

    function loopTyping() {
      if (word.length > 0) {
        currentText += word.shift();
        document.getElementById("typewrite").innerHTML = currentText;
        setTimeout(loopTyping, 200);
      } else {
        setTimeout(deleteNow, 1000); // Pause before starting to delete
      }
    }
    loopTyping();
  }

  function deleteNow() {
    const word = words[i].split("");
    let currentText = staticText + words[i];

    function loopDeleting() {
      if (word.length > 0) {
        word.pop();
        currentText = staticText + word.join("");
        document.getElementById("typewrite").innerHTML = currentText;
        setTimeout(loopDeleting, 200);
      } else {
        i = (i + 1) % words.length;
        setTimeout(typeNow, 500); // Pause before starting to type next word
      }
    }
    loopDeleting();
  }

  typeNow();
};


const initializeActivityFeeds = () => {
  const activityFeedsNode = document.getElementById('activity-feeds')
  const activityFeedData = JSON.parse(activityFeedsNode.getAttribute('data').replace(/&quot;/g, '"'))

  if (activityFeedsNode && activityFeedData) {
    ReactDOM.render( < ActivityFeed { ...activityFeedData } />, activityFeedsNode)
  }
}

const fetchLatestNewsData = () => {
  getLatestNews((items) => {
    const setItems = groupIntoSetsOfThree(items);
    const htmls = setItems[0].map((item) => {
      return `
        <li class="flex-shrink-0 h-full w-auto lg:w-1/3 md:w-1/4 w-2/3 lg:flex-shrink">
          <a href=${item.link}>
            <img src="${item.imageSrc}" class="w-full lg:h-60 h-40 mb-4"/>
            <p class="text-black-tones-50 text-base font-semibold leading-normal">${item.title}</p>
          </a>
        </li>
      `;
    });

    $(".latest-news-list").html(htmls.join(""));
  });
};

const xmlToJson = (xml) => {
  let obj = {};

  if (xml.nodeType === Node.ELEMENT_NODE) {
    if (xml.attributes.length > 0) {
      obj["@attributes"] = {};
      for (let i = 0; i < xml.attributes.length; i++) {
        const attribute = xml.attributes[i];
        obj["@attributes"][attribute.nodeName] = attribute.nodeValue;
      }
    }
  } else if (xml.nodeType === Node.TEXT_NODE) {
    obj = xml.nodeValue;
  }

  if (xml.hasChildNodes()) {
    for (let j = 0; j < xml.childNodes.length; j++) {
      const child = xml.childNodes[j];
      const nodeName = child.nodeName;
      if (typeof obj[nodeName] === "undefined") {
        obj[nodeName] = xmlToJson(child);
      } else {
        if (!Array.isArray(obj[nodeName])) {
          obj[nodeName] = [obj[nodeName]];
        }
        obj[nodeName].push(xmlToJson(child));
      }
    }
  }

  return obj;
};

const groupIntoSetsOfThree = (items) => {
  const groups = [];

  for (let i = 0; i < items.length; i += 3) {
    const group = items.slice(i, i + 3);
    groups.push(group);
  }

  return groups;
};

const getLatestNews = (callback) => {
  const buildingRSSUrl =
    "https://www.serviceseeking.com.au/industry-insights/tag/building/rss.xml";
  $.ajax({
    url: buildingRSSUrl,
    type: "GET",
    dataType: "xml",
    success: function (xmlResponse) {
      const jsonData = xmlToJson(xmlResponse);
      const items = jsonData.rss.channel.item;
      const results = items.map((item) => {
        const description = $(item.description["#text"]);
        return {
          title: item.title["#text"],
          imageSrc: description.find("img").attr("src"),
          link: item.link["#text"],
        };
      });

      callback(results);
    },
  });
};

$(function () {
  initializeActivityFeeds();
  initializeCarousel();
  initializeWhatTheyThinkCarousel();
  initializeTabs();
  initializeTypewriter();
  fetchLatestNewsData();
});
